import React from 'react';
import {
    Box,
    // Image
} from '@chakra-ui/react';

function ProjectItem({ children, imageSrc, imageAlt, title, description }) {

    return <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" bg="white">
        {/* <Image src={imageSrc} alt={imageAlt} /> */}
        <Box maxW="800px" p="6">
            <Box
                mt="1"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated>
                {title}
            </Box>
            <Box>
                {description}
            </Box>
            <Box pt={5}>
                {children}
            </Box>
        </Box>
    </Box>
}

export default ProjectItem;