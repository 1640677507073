import React, { useState } from 'react';
import {
    HStack,
    Icon,
    Box
} from '@chakra-ui/react';
import { ImFacebook2, ImGithub, ImInstagram, ImLinkedin, ImTwitter } from "react-icons/im";

function SocialButtons() {
    const FACEBOOK = 0;
    const GITHUB = 1;
    const INSTAGRAM = 2;
    const LINKEDIN = 3;
    const TWITTER = 4;

    const [hoverId, setHoverId] = useState(-1);

    const onMouseEnter = (id) => {
        setHoverId(id);
    };

    const onMouseLeave = () => {
        setHoverId(-1);
    };

    const getButtonColor = (id) => {
        if (hoverId === id || hoverId === -1) {
            return 'black';
        }

        return 'gray.400';
    }

    return <HStack spacing="20px">
        <Box as="button" onClick={() => window.open("https://facebook.com/lumtwj", "_blank")}>
            <Icon
                boxSize="2em"
                as={ImFacebook2}
                onMouseEnter={() => onMouseEnter(FACEBOOK)}
                onMouseLeave={onMouseLeave}
                color={getButtonColor(FACEBOOK)} />
        </Box>
        <Box as="button" onClick={() => window.open("https://github.com/lumtwj", "_blank")}>
            <Icon
                boxSize="2em"
                as={ImGithub}
                onMouseEnter={() => onMouseEnter(GITHUB)}
                onMouseLeave={onMouseLeave}
                color={getButtonColor(GITHUB)} />
        </Box>
        <Box as="button" onClick={() => window.open("https://instagram.com/lumtwj", "_blank")}>
            <Icon
                boxSize="2em"
                as={ImInstagram}
                onMouseEnter={() => onMouseEnter(INSTAGRAM)}
                onMouseLeave={onMouseLeave}
                color={getButtonColor(INSTAGRAM)} />
        </Box>
        <Box as="button" onClick={() => window.open("https://www.linkedin.com/in/joelum", "_blank")}>
            <Icon
                boxSize="2em" as={ImLinkedin}
                onMouseEnter={() => onMouseEnter(LINKEDIN)}
                onMouseLeave={onMouseLeave}
                color={getButtonColor(LINKEDIN)} />
        </Box>
        <Box as="button" onClick={() => window.open("https://twitter.com/lumtwj", "_blank")}>
            <Icon
                boxSize="2em"
                as={ImTwitter}
                onMouseEnter={() => onMouseEnter(TWITTER)}
                onMouseLeave={onMouseLeave}
                color={getButtonColor(TWITTER)} />
        </Box>
    </HStack>;
}

export default SocialButtons;