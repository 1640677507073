import React, { useState, useEffect, useRef } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Stack,
  Center,
  Avatar,
  Heading,
  Tag,
  Button,
  ScaleFade,
  theme,
} from '@chakra-ui/react';
// import { ColorModeSwitcher } from './ColorModeSwitcher';
import me from './assets/me.jpg';
import SocialButtons from './widgets/SocialButtons';
import Skills from './sections/Skills';
import Projects from './sections/Projects';
import Contact from './sections/Contact';

function App() {
  const [showAvatar, setShowAvatar] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showSubHeader, setShowSubHeader] = useState(false);
  const [showSocialButtons, setShowSocialButtons] = useState(false);
  const [showContactButton, setShowContactButton] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const contactRef = useRef();

  useEffect(() => {
    setTimeout(() => setShowAvatar(true), 0);
    setTimeout(() => setShowHeader(true), 500);
    setTimeout(() => setShowSubHeader(true), 1000);
    setTimeout(() => setShowContactButton(true), 1500);
    setTimeout(() => setShowSocialButtons(true), 2000);
    setTimeout(() => setShowContent(true), 2500);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Stack direction={["column", "column", "row"]}>
        <Center h={[null, null, "100vh"]} w={["100%", "100%", "40%"]}>
          <Box px={12} py={10}>
            <ScaleFade initialScale={0.9} in={showAvatar}>
              <Avatar size="2xl" src={me} />
            </ScaleFade>
            <ScaleFade initialScale={0.9} in={showHeader}>
              <Heading size="xl" pt={10} pb={5}>HEY WORLD, I'M <Tag size="xl" fontWeight="bold" px={3}>JOE LUM</Tag></Heading>
            </ScaleFade>
            <ScaleFade initialScale={0.9} in={showSubHeader}>
              <Text size="md" pb={10}>A software developer who is based in Singapore. I believe that writing apps can help to simplify repetitive tasks and makes the world a better place to be in.</Text>
            </ScaleFade>
            <ScaleFade initialScale={0.9} in={showSocialButtons}>
              <SocialButtons />
            </ScaleFade>
            <ScaleFade initialScale={0.9} in={showContactButton}>
              <Button colorScheme="blue" size="lg" mt={10} onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                Contact
              </Button>
            </ScaleFade>
          </Box>
        </Center>
        <Box h={[null, null, "100vh"]} w={["100%", "100%", "60%"]} overflowY="auto">
          <ScaleFade initialScale={0.9} in={showContent}>
            <Skills />
            <Projects />
            <Contact ref={contactRef} />
          </ScaleFade>
        </Box>
      </Stack>
    </ChakraProvider>
  );
}

export default App;
