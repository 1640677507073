import React from 'react';
import {
    Box,
    Heading,
    Wrap,
    WrapItem,
    Tag,
} from '@chakra-ui/react';

function Skills() {
    return <Box bg="teal.200" p={20}>
        <Heading as="h2" size="xl" pb={7}>Skills</Heading>
        <Heading as="h4" size="md" pb={3}>Languages</Heading>
        <Wrap>
            <WrapItem>
                <Tag>Dart</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>Java</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>JavaScript</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>Python</Tag>
            </WrapItem>
        </Wrap>
        <Heading as="h4" size="md" pt={3} pb={3}>Framework</Heading>
        <Wrap>
            <WrapItem>
                <Tag>Django</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>Flutter</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>React</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>React Native</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>Serverless</Tag>
            </WrapItem>
        </Wrap>
        <Heading as="h4" size="md" pt={3} pb={3}>Databases</Heading>
        <Wrap>
            <WrapItem>
                <Tag>Firestore</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>MongoDB</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>MySQL</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>Neo4J</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>PostgreSQL</Tag>
            </WrapItem>
        </Wrap>
        <Heading as="h4" size="md" pt={3} pb={3}>Cloud</Heading>
        <Wrap>
            <WrapItem>
                <Tag>Amazon Web Service (AWS)</Tag>
            </WrapItem>
            <WrapItem>
                <Tag>Google Cloud Platform (GCP)</Tag>
            </WrapItem>
        </Wrap>
    </Box>
}

export default Skills;