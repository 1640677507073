import React from 'react';
import {
    Box,
    Heading,
    Button,
    Wrap,
    WrapItem
} from '@chakra-ui/react';
import ProjectItem from '../widgets/ProjectItem';
// import WhereGotTimeBanner from '../assets/where_got_time.png'

function Projects() {
    return <Box bg="orange.200" p={20}>
        <Heading as="h2" size="xl" pb={7}>Projects</Heading>
        <Wrap spacing={10}>
            <WrapItem>
                <ProjectItem
                    imageSrc="https://bit.ly/2Z4KKcF"
                    imageAlt="lumtw.com"
                    title="LUMTW.COM"
                    description="A website written in React to introduce myself to the world. Inspired by Hugo Uilite theme that I previously used.">
                    <Button colorScheme="blue" variant="link" onClick={() => window.open("https://github.com/uicardioHQ/hugo-uilite", "_blank")}>
                        More Details
                    </Button>
                </ProjectItem>
            </WrapItem>
            <WrapItem>
                <ProjectItem
                    imageSrc="https://bit.ly/2Z4KKcF"
                    imageAlt="Crypto PI"
                    title="CRYPTO-PI"
                    description="A python script which periodically retrieves cryptocurrency chart and displays it on a Raspberry Pi e-ink display.">
                    <Button colorScheme="blue" variant="link" onClick={() => window.open("https://github.com/lumtwj/crypto-pi", "_blank")}>
                        More Details
                    </Button>
                </ProjectItem>
            </WrapItem>
            <WrapItem>
                <ProjectItem
                    imageSrc="https://bit.ly/2Z4KKcF"
                    imageAlt="Parkar"
                    title="PARKAR"
                    description="A utility application made for motorists to get information on the nearest EV charging points and parking lots in Singapore.">
                    <Button colorScheme="blue" variant="link" onClick={() => window.open("https://parkar.asia", "_blank")}>
                        More Details
                    </Button>
                </ProjectItem>
            </WrapItem>
            <WrapItem>
                <ProjectItem
                    // imageSrc={WhereGotTimeBanner}
                    imageSrc="https://bit.ly/2Z4KKcF"
                    imageAlt="Where got time?"
                    title="WHERE GOT TIME?"
                    description="A countdown application to help full-time National Servicemen (NSF) track the progress in serving their National Service."
                >
                    <Button colorScheme="blue" variant="link" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.mOcentury.wgt", "_blank")}>
                        More Details
                    </Button>
                </ProjectItem>

            </WrapItem>
        </Wrap>

    </Box>;
}

export default Projects;