import React, { useState, forwardRef } from 'react';
import {
    Box,
    Heading,
    Stack,
    Input,
    Textarea,
    Button,
    FormControl,
    FormLabel,
    useToast
} from '@chakra-ui/react';

const Contact = forwardRef((props, ref) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const toast = useToast()

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const submit = () => {
        console.log(name, email, message)
        fetch("https://formspree.io/f/xleaodoa", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, email, message })

        }).then(response => response.json())
            .then(data => {
                if (data.ok) {
                    toast({
                        title: "Your message has been sent",
                        status: "success",
                        position: "bottom-right",
                        isClosable: true,
                    })
                }
                else {
                    toast({
                        title: "There are some issues sending your message. Try again in a while.",
                        status: "success",
                        position: "bottom-right",
                        isClosable: true,
                    })
                }
            })
    };

    return <Box ref={ref} bg="gray.50" p={20}>
        <Heading as="h2" size="xl" pb={7}>Contact</Heading>
        <Box maxW="800px">
            <FormControl>
                <Stack pb={2} direction={["column", null, "row"]}>
                    <Box w={["100%", null, "50%"]}>
                        <FormLabel mb="8px">Name</FormLabel>
                        <Input
                            onChange={handleNameChange}
                            value={name}
                            placeholder="Name"
                            variant="filled"
                            size="sm"
                        />
                    </Box>
                    <Box w={["100%", null, "50%"]}>
                        <FormLabel mb="8px">Email</FormLabel>
                        <Input
                            onChange={handleEmailChange}
                            value={email}
                            placeholder="Email"
                            variant="filled"
                            size="sm"
                        />
                    </Box>
                </Stack>
                <FormLabel mb="8px">Message</FormLabel>
                <Textarea
                    mb={5}
                    value={message}
                    onChange={handleMessageChange}
                    placeholder="Message"
                    variant="filled" />
                <Button colorScheme="blue" size="md" isDisabled={name === '' || email === '' || message === ''} onClick={submit}>
                    Submit
                </Button>
            </FormControl>

        </Box>

    </Box>;
});

export default Contact;